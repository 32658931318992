div.MuiAvatar-circular > img {
  width: 75% !important;
}

.allyContainer img {
  transition: all 0.2s ease-in-out;
}

.allyContainer img:hover {
  opacity: 1 !important;
  transform: scale(1.1);
}

@media (max-width: 420px) {
  #landing-h1 {
    font-size: 2.2rem;
  }
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-lg-6 {
    padding-left: 0;
  }

  #paypal-btn {
    width: 2rem;
    font-size: 10px;
    left: 75%;
  }
  #paypal-btn img {
    width: 100%;
  }
}

#paypal-btn {
  position: sticky;
  left: 85%;
  bottom: 12%;
}
